export const environment = {
  production: false,

  clientName: 'demo',
  apiGatewayFront: 'https://adminproveedoressipote.takami.co/',
  apiGatewayBackOffice: 'https://adminproveedoressipote.takami.co/',
  apiUrl: 'https://adminproveedoressipote.takami.co/',
  urlPath: 'https://adminproveedoressipote.takami.co/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdRsFUqAAAAAG-WZhDtULfp_YELhl90vpQwO9ES', DEMO
  // siteKeyCaptcha: '6LdRsFUqAAAAAG-WZhDtULfp_YELhl90vpQwO9ES' ZX

  siteKeyCaptcha: '6LdRsFUqAAAAAG-WZhDtULfp_YELhl90vpQwO9ES',

  googleAnalyticsCode: 'UA-140785247-1',
};